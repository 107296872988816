import {$,jQuery} from 'jquery';
/*IMPORT SCSS*/
import '../styles/index.scss';
import '../styles/extras.scss';

/*MAIN APPLICATION*/
import './forside.js';

const navSlide = () => {
  const burger = document.querySelector('.burger');
  const nav = document.querySelector('.nav-links');
  const navLinks = document.querySelectorAll('.nav-links li');


  burger.addEventListener('click', () => {
    //Toggle nav
    nav.classList.toggle('nav-active');

    //Burger animation
    burger.classList.toggle('toggle');
  });

}

navSlide();
