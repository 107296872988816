$(window).scroll(function() {
  var scrollTop = $(this).scrollTop();

  $('.video .overlay').css({
    opacity: function() {
      var elementHeight = $(this).height();
      return 1 - (elementHeight - scrollTop) / elementHeight;
    }
  });

  $('.video .scroll').css({
    opacity: function() {
        var elementHeight = $(this).height(),
        opacity = ((elementHeight - scrollTop) / elementHeight);
        return opacity;
    }
  });

  /*$('.image .image-background').css({
    transform: function() {
        var elementHeight = $(this).height(),
        transformNumber = "translateY(" + (((elementHeight - scrollTop) * 10) / elementHeight) + "%)";
        console.log(transformNumber);
        return transformNumber;
    }
  });*/



});
